<template>
    <div class="addimage-banner imagebanner-area">
        <b-modal id="viewMorePhotos" centered>
            <div class="modalTitle d-flex justify-content-start">
                <span class="closeModal" @click="$bvModal.hide('viewMorePhotos')">
                    <span class="left-angle"></span>
                </span>
            </div>
            <div class="modalContent p-0">
                <template v-if="files.length">
                    <div class="preview-container" >
                        <div v-for="file,index in files" :key="file.name" class="preview-card">
                            <div class="image-section" @click="openGallery(index)">
                            <img class="preview-img" :src="generateURL(file)" />
                            </div>
                            <CoolLightBox :items="images" :index="galleryIndex" @close="galleryIndex = null"></CoolLightBox>
                            <div class="remove-icon">
                                <button type="button" @click="remove(files.indexOf(file))" title="Remove file"> <b>×</b> </button>
                            </div>
                        </div>
                        <div class="preview-card" v-if="filesCount < 12">
                            <div class="image-section">
                                <div class="image-height">
                                    <div class="file-input upload-btn">
                                        <input type="file" multiple name="file-input" id="file-input" class="file-input__input" @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png" />
                                        <label class="file-input__label" for="file-input"> <span class="icon-upload"></span><p>click here for more image.</p></label>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </b-modal>
    </div>
</template>
<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
    name: 'ViewMorePhotoModal',
    components:{
        CoolLightBox
    },
    data() {
        return {
            files: [],
            filesCount:0,
            galleryIndex: null
        };
    },
    computed: {
        images() {
            return this.files.map(file => this.generateURL(file));
        },
    },
    mounted() {
        let app = this;
        app.$root.$on("selected-images", ($event) => {
            app.files = $event;
        });
    },
    methods: {
        remove(i) {
            this.files.splice(i, 1);
        },
        onChange() {
            this.files.push(...this.$refs.file.files);
        },
        generateURL(file) {
            let fileSrc = URL.createObjectURL(file);
            return fileSrc;
        },
        openGallery(index) {
        this.galleryIndex = index;
      },
      closeGallery() {
        this.galleryIndex = null;
        },
    },
    watch: {
        files(value) {
            let app = this;
            app.filesCount = value.length;
        },
    },
}
</script>