<template>

    <div>

        <b-modal id="reqvideoModal" centered>

            <div class="modalTitle">

                <h2>Video Request</h2>

                <span class="closeModal" @click="$bvModal.hide('reqvideoModal')">

                    <span class="icon-close"></span>

                </span>

            </div>

            <div class="modalContent videoRequest pt-3">

                <p>To request for the professional video shots of your property, Please fill below form</p>

                <ContactForm :requestData="contactData" :textMsg="textMsg" />

                <div class="info-disclaimer">

                    <p>We'll contact you for the video shoot after submiting form</p>

                </div>

            </div>

        </b-modal>

    </div>

</template>

<script>
import ContactForm from '../../../../components/ContactFrom.vue'
import store from "@/store";
export default {

    name: 'RequestVideoModal',
    components:{ContactForm},
    data(){
        return {
            contactData:{
                name: store.getters.user.name,
                email: store.getters.user.email,
                phone_number: store.getters.user.mobile_number,
                source: "VIDEO_REQUEST",
            },
            textMsg:"",
        }
    },

}

</script>