<template>
    <div class="col-xl-4">
        <div class="addimage-banner imagebanner-area">
            <div class="no-image-area" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <template v-if="files.length">
                    <div class="preview-container" >
                        <div v-for="file in files" :key="file.name" class="preview-card">
                            <template v-if="files.indexOf(file) <= 4">
                                <div class="image-section">
                                    <img v-if="files.indexOf(file)==0" class="preview-img single-image" :src="generateURL(file)" />
                                    <img v-else class="preview-img" :src="generateURL(file)" />
                                    <div v-if="files.indexOf(file) == 4" v-b-modal.viewMorePhotos class="view-more">+{{ filesCount }}</div>
                                </div>
                                <div class="remove-icon">
                                    <button type="button" @click="remove(files.indexOf(file))" title="Remove file"> <b>×</b> </button>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <span class="icon-image"></span>
                    <h3>There is no any image, please upload</h3>
                </template>
                <template v-if="filesCount < 12">
                <p>Add up to 12 photos. More photos get more replies!</p>
                <h6 class="brand-color">Drag & drop image here</h6>
                </template>
                <div class="file-input upload-btn">
                    <input type="file" multiple name="file-input" class="file-input__input hidden-input" @change="onChange" ref="file" accept=".jpg,.jpeg,.png" />
                </div>
            </div>
            <ul>
                <li>
                    <div class="file-input upload-btn">
                        <input type="file" multiple name="file-input" id="file-input" class="file-input__input" @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png" />
                        <label class="file-input__label" for="file-input"> <span class="icon-upload"></span> <span>Upload Image</span></label>
                    </div>
                </li>
                <li>
                    <div class="video-request" v-b-modal.reqvideoModal>
                        <span class="icon-video"></span>
                        Request for Video
                    </div>
                </li>
            </ul>
            <button @click="formRequestSubmit" class="univ-btn w-100"><b-spinner v-if="loginLoader" small></b-spinner> Submit Property</button>
        </div>
    </div>
            
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import Helper from '../../../../services/helper';
export default {
    name: 'AddImageBanner',
    props:{
        formRequestData:{
            required: true,
        }
    },
   
    data() {
        return {
            loginLoader: false,
            message:"",
            isDragging: false,
            files: [],
            filesCount:0,
        };
    },
    methods: {
        formRequestSubmit(e){
            e.preventDefault();
            let app = this;
            app.loginLoader = true;
            Helper.showSpinner();
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(api.getUrl('/user/property/store'), app.formRequestData ,config).then(function (response) {
                if(response.data.success){
                    app.loginLoader = false;
                    app.files = [];
                    app.message = response.data.message;
                    Helper.hideSpinner();
                    app.$toast.success(response.data.message,{position: "top-center",timeout: 1500});
                }else{
                    if(response.data.message){
                        app.loginLoader = false;
                        Helper.hideSpinner();
                        app.message = response.data.message;
                        app.$toast.error(response.data.message,{position: "top-center",timeout: 1500});
                    }else if(response.data.errors.length > 0){
                        app.loginLoader = false;
                        Helper.hideSpinner();
                        app.$emit('form-errors',response.data.errors);
                    }
                }
            });
        },
        onChange() {
            let app = this;
            let newFilesCount = app.$refs.file.files.length;
            if((app.filesCount+newFilesCount) <= 12){
                app.files.push(...app.$refs.file.files);
            }else{
                app.$toast.error("Exceeds the image limits!",{position: "top-center",timeout: 1500});
            }
        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.onChange();
            this.isDragging = false;
        },
        remove(i) {
            this.files.splice(i, 1);
        },
        generateURL(file) {
            let fileSrc = URL.createObjectURL(file);
            setTimeout(() => {
                URL.revokeObjectURL(fileSrc);
            }, 1000);
            return fileSrc;
        },
    },
    watch: {
        files(value) {
            let app = this;
            app.filesCount = value.length;
            app.$emit('selected-images',value);
            app.$root.$emit('selected-images',value);
        },
    },
}
</script>