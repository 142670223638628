<template>
<div class="row">
    <div class="col-xl-8">
        <div class="addForms display-card p-4">
            <h3>Categories</h3>
            <div class="form-group row">
                <div class="col-xl-4 mb-3">
                    <label>Listed On</label>
                    <multiselect
                        placeholder="Select Purpose"
                        v-model="purpose"
                        :options="property_purpose"
                        :custom-label="purposeLabel"
                        @select="getSelectedPurpose"
                        @remove="removedSelectedPurpose"
                    >
                    </multiselect>
                    <span class="error-field" v-if="formRequestError.purpose && formRequest.purpose ==''"><span class="icon-warning"></span> {{ formRequestError.purpose }}</span>
                </div>
                <div class="col-xl-4 mb-3">
                    <label>Category <span class="required-field">*</span></label>
                    <multiselect
                        placeholder="Select Category"
                        v-model="category"
                        :options="property_category"
                        :custom-label="categoryLabel"
                        @select="getSelectedCategory"
                        @remove="removedSelectedCategory"
                    >
                    </multiselect>
                    <span class="error-field" v-if="formRequestError.category_id && formRequest.category_id ==''"><span class="icon-warning"></span> {{ formRequestError.category_id }}</span>
                </div>
                <div class="col-xl-4 mb-3">
                    <label>{{ selectedCategoryName }} Type <span class="required-field">*</span></label>
                    <multiselect
                        placeholder="Select Type"
                        v-model="types"
                        :options="category_types"
                        :custom-label="typeLabel"
                        @select="getSelectedType"
                        @remove="removedSelectedType"
                    >
                    </multiselect>
                    <span class="error-field" v-if="formRequestError.category_type_id && formRequest.category_type_id ==''"><span class="icon-warning"></span> {{ formRequestError.category_type_id }}</span>
                </div>
            </div>
            
            <h3>Descriptions</h3>
            <div class="form-group row">
                <div class="col-12 mb-3">
                    <label>Title <span class="required">*</span></label>
                    <input v-model="formRequest.generals.title" type="text" class="univ-input" placeholder="Enter Property Title" >
                    <span class="error-field" v-if="formRequestError.title && formRequest.generals.title ==''"><span class="icon-warning"></span> {{ formRequestError.title }}</span>
                </div>
                <div class="col-12 mb-3">
                    <label>Description</label>
                    <textarea v-model="formRequest.description" class="univ-input" rows="4"></textarea>
                </div>
            </div>
            <div class="form-group row mb-5">
                <div class="col-xl-3 mb-3">                                
                    <label>District <span class="required">*</span></label>
                    <select class="univ-input" v-model="formRequest.generals.location_id" @change="selecteDistrict($event)">
                        <option value="">Select District</option>
                        <option v-for="(item,n) in location" :key="n" :value="item.id">{{ item.name }}</option>
                    </select>
                    <span class="error-field" v-if="formRequestError.location_id && formRequest.generals.location_id ==''"><span class="icon-warning"></span> {{ formRequestError.location_id }}</span>
                </div>
                <div class="col-xl-9 mb-3">                                
                    <label>Address<span class="required">*</span></label>
                    <LocationAutocomplete @selected-location="selectedLocationHandle($event)" />
                    <span class="error-field" v-if="formRequestError.address && formRequest.generals.address ==''"><span class="icon-warning"></span> {{ formRequestError.address }}</span>
                </div>
                <div class="col-xl-4 mb-3">                                
                    <label>Price <span class="required">*</span></label>
                    <input v-model="formRequest.price" type="text" class="univ-input" placeholder="Property price" >
                    <span class="error-field" v-if="formRequestError.price && formRequest.generals.price ==''"><span class="icon-warning"></span> {{ formRequestError.price }}</span>
                </div>
                <div class="col-xl-4 mb-3">                                
                    <label>Negotiable</label>
                    <select class="univ-input" v-model="formRequest.generals.price_condition">
                        <option value="Negotiable">Negotiable</option>
                        <option value="Fixed">Fixed</option>
                    </select>
                </div>
                <div class="col-xl-4 mb-3" v-if="formRequest.purpose =='rent'">                                
                    <label>Price For <span class="required">*</span></label>
                    <select class="univ-input" v-model="formRequest.generals.price_for">
                        <option value="Month">Month</option>
                        <option value="Year">Year</option>
                    </select>
                </div>
                <div class="col-xl-4 mb-3">
                    <label for="">Land Mark</label>
                    <input v-model="formRequest.generals.land_mark" type="text" class="univ-input" placeholder="Land Mark">
                </div>
                <div class="col-xl-4 mb-3">                                
                    <label>Expiry Date</label>
                    <select class="univ-input" v-model="formRequest.generals.expire_date">
                        <option value="Negotiable">Negotiable</option>
                        <option value="Fixed">Fixed</option>
                    </select>
                </div>
            </div>

            <h3>Specifications</h3>
            <div class="form-group row">
                <div class="col-xl-3 mb-3">                                
                    <label>Land Size <span class="required">*</span></label>
                    <input v-model="formRequest.specifications.land_size" type="text" class="univ-input" placeholder="0-0-0-0" @keyup="onInputLandSize" @blur="onInputLandSize" >
                    <span class="error-field" v-if="formRequestError.land_size && formRequest.specifications.land_size ==''"><span class="icon-warning"></span> {{ formRequestError.land_size }}</span>
                </div>
                <div class="col-xl-3 mb-3">                                
                    <label>Road Size <span class="required">*</span></label>
                    <select class="univ-input" v-model="formRequest.specifications.road_size">
                        <option value="">Road Size</option>
                        <option value="">Less Than 5 ft.</option>
                        <option value="">5 - 8 ft.</option>
                        <option value="">9 - 12 ft.</option>
                        <option value="">9 - 12 ft.</option>
                        <option value="">13 - 20 ft.</option>
                        <option value="">Above 20 ft.</option>
                        <option value=""> Goreto </option>
                        <option value=""> No Road </option>
                    </select>
                    <span class="error-field" v-if="formRequestError.road_size && formRequest.specifications.road_size ==''"><span class="icon-warning"></span> {{ formRequestError.road_size }}</span>
                </div>
                <div class="col-xl-3 mb-3">                                
                    <label>Road Type <span class="required">*</span></label>
                    <select class="univ-input" v-model="formRequest.specifications.road_type">
                        <option value="">Pitched</option>
                        <option value="">Gravel</option>
                        <option value="">Soil (Mato)</option>
                        <option value="">Not Road</option>
                        <option value="">Others</option>
                    </select>
                    <span class="error-field" v-if="formRequestError.road_type && formRequest.specifications.road_type ==''"><span class="icon-warning"></span> {{ formRequestError.road_type }}</span>
                </div>
                <div class="col-xl-3 mb-3">
                    <label for="">Built Year</label>
                    <input v-model="formRequest.specifications.build_date" type="text" class="univ-input" placeholder="Year">
                </div>

                <template v-if="category !=='' && selectedCategoryName !=='Land' ">
                    <div class="col-xl-3 mb-3">
                        <label for="">Floor <span class="required">*</span></label>
                        <input v-model="formRequest.specifications.floor" type="text" class="univ-input" placeholder="Floor">
                    </div>
                    <div class="col-xl-3 mb-3">
                        <label for="">Bedroom <span class="required">*</span></label>
                        <input v-model="formRequest.specifications.bedroom" type="text" class="univ-input" placeholder="Bedroom">
                    </div>
                    <div class="col-xl-3 mb-3">
                        <label for="">Bathroom <span class="required">*</span></label>
                        <input v-model="formRequest.specifications.bathroom" type="text" class="univ-input" placeholder="Bathroom">
                    </div>
                    <div class="col-xl-3 mb-3">
                        <label for="">Living Room <span class="required">*</span></label>
                        <input v-model="formRequest.specifications.livingroom" type="text" class="univ-input" placeholder="Living Room">
                    </div>
                    <div class="col-xl-3 mb-3">
                        <label for="">Kitchen <span class="required">*</span></label>
                        <input v-model="formRequest.specifications.kitchen" type="text" class="univ-input" placeholder="Kitchen">
                    </div>
                    <div class="col-xl-3 mb-3">                                
                        <label>Furnishing <span class="required">*</span></label>
                        <select class="univ-input" v-model="formRequest.specifications.furnishing">
                            <option value="">None</option>
                            <option value="">Full</option>
                            <option value="">Semi</option>
                        </select>
                    </div>
                </template>
            </div>

            <div class="form-group row">
                <div class="col-xl-3 mb-3">                                 
                    <label>Buildup Area<span class="required">*</span></label>
                    <input v-model="formRequest.specifications.buildup_area" type="number" min="0" class="univ-input" placeholder="Buildup area" >
                    <span class="error-field" v-if="formRequestError.buildup_area && formRequest.specifications.buildup_area ==''"><span class="icon-warning"></span> {{ formRequestError.buildup_area }}</span>
                </div>
                <div class="col-xl-3 mb-3">                                
                    <label>Unit <span class="required">*</span></label>
                    <select class="univ-input" v-model="formRequest.specifications.area_type">
                        <option value="">Select Unit</option>
                        <option v-for="(item,o) in area_units" :key="o" :value="item.id">{{ item.title }}</option>
                    </select>
                    <span class="error-field" v-if="formRequestError.area_type && formRequest.specifications.area_type ==''"><span class="icon-warning"></span> {{ formRequestError.area_type }}</span>
                </div>
                
                
            </div>
            <template v-if="category_features.length > 0">
            
            <h3>Features</h3>
            <div class="form-group featuredbox">
                <ul>
                    <li v-for="(item,m) in category_features" :key="m" >
                        <input class="styled-checkbox" :id="'feature'+m" type="checkbox" v-model="formRequest.selected_features" :value="item.id">
                        <label :for="'feature'+m">{{ item.title }}</label>
                    </li>
                </ul>
            </div>
            </template>
        </div>                    
    </div>
    <AddImageBanner :formRequestData="formRequest" @form-errors="handleFormErrors($event)" @selected-images="handleSelectedImages($event)"/>
</div>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import store from "@/store";
import 'vue2-datepicker/index.css';
import AddImageBanner from '@/views/Users/AddProperty/partials/AddImageBanner.vue';
import Multiselect from 'vue-multiselect'
import LocationAutocomplete from '../../../../components/LocationAutocomplete.vue';
export default {
    name: 'AddForm',
    components: {AddImageBanner,Multiselect,LocationAutocomplete },
    data() {
        return {
            land_size:"",
            purpose:"",
            category:"",
            types:"",
            property_purpose:[],
            property_category:[],
            property_categories_detail:[],
            selecteCategoryID:"",
            selectedCategoryName:"",
            selectedDistrictName:"",
            category_types:[],
            category_features:[],
            location:[],
            area_units:[],

            formRequest:{
                client_id: store.getters.user.id,
                purpose:"",
                category_id:"",
                category_type_id:"",
                generals:{
                    title:"",
                    description:"",
                    location_id:"",
                    address:"",
                    land_mark:"",
                    lat:"",
                    lon:"",
                    price:"",
                    price_condition:"Negotiable",
                    price_for:"Month",
                    currency:"NPR",
                    expire_date:"",
                },
                specifications:{
                    land_size:"",
                    road_size:"",
                    road_type:"",
                    build_date:"",

                    floor:"",
                    bedroom:"",
                    bathroom:"",
                    livingroom:"",
                    kitchen:"",
                    furnishing:"",

                    buildup:"",
                    area_type:"",
                },
                selected_features: [],
                selected_images: [],
                adsOption:false,
            },
            formRequestError:{
                purpose:"",
                category_id:"",
                category_type_id:"",
                title:"",
                location_id:"",
                address:"",
                price:"",
            },
        };        
    },
    mounted() {
        let app = this;
        app.renderInfo();
    },
    methods: {
        async renderInfo(){
            let app = this;
            await axios.get(api.getUrl('/general-info')).then(function (response) {
                let responseData        = response.data.data;
                if(response.data.success){
                    let purposeRows = [];
                    responseData.property_purpose.forEach(function (item) {
                        purposeRows.push({title:item.title,slug:item.slug});
                    });
                    app.property_purpose = purposeRows;
                    let categoryRows = [];
                    responseData.property_category.forEach(function (item) {
                        categoryRows.push({title:item.title,id:item.id});
                    });
                    app.property_category = categoryRows;
                    app.property_categories_detail   = responseData.property_category;

                    app.property_type       = responseData.property_type;
                    app.location            = responseData.location;
                    app.area_units          = responseData.area_units;
                }
            }).finally(() => {
                
            });
        },
        handleFormErrors($event){
            let app = this;
            $event.forEach(function(item) {
                if(item.key == "purpose"){
                    app.formRequestError.purpose = item.message;
                }else if(item.key == "category_id"){
                    app.formRequestError.category_id = item.message;
                }else if(item.key == "category_type_id"){
                    app.formRequestError.category_type_id = item.message;

                }else if(item.key == "title"){
                    app.formRequestError.title = item.message;
                }else if(item.key == "location_id"){
                    app.formRequestError.location_id = item.message;
                }else if(item.key == "address"){
                    app.formRequestError.address = item.message;
                }else if(item.key == "price"){
                    app.formRequestError.price = item.message;

                }else if(item.key == "land_size"){
                    app.formRequestError.specifications.land_size = item.message;
                }else if(item.key == "area_type"){
                    app.formRequestError.area_type = item.message;
                }
            }); 
        },
        handleSelectedImages($event){
            let app = this;
            app.formRequest.selected_images = $event;
        },
        purposeLabel(option) {
            return `${option.title}`;
        },
        getSelectedPurpose(option){
            let app = this;
            app.formRequest.purpose = option.slug;
        },
        removedSelectedPurpose(){
            let app = this;
            app.formRequest.purpose = "";
        },
        categoryLabel(option) {
            return `${option.title}`;
        },
        getSelectedCategory(option){
            let app = this;
            app.formRequest.category_id = option.id;
            // onchange event
            app.selecteCategoryID = option.id;
            app.selectedCategoryName = option.title;
            if(app.selecteCategoryID == ''){
                app.resetTypes();
            }
            app.property_categories_detail.forEach(function (item) {
                if (item.id == app.selecteCategoryID) {
                    let typesRows = [];
                    item.category_types.forEach(function (item) {
                        typesRows.push({title:item.title,id:item.id});
                    });
                    app.category_types = typesRows;
                    app.category_features = item.category_features;
                }
            });
        },
        removedSelectedCategory(){
            let app = this;
            app.formRequest.category_id = "";
            app.resetTypes();
        },
        typeLabel(option) {
            return `${option.title}`;
        },
        getSelectedType(option){
            let app = this;
            app.formRequest.category_type_id = option.id;
        },
        removedSelectedType(){
            let app = this;
            app.formRequest.category_type_id = "";
        },
        resetTypes(){
            let app = this;
            app.selectedCategoryName = "";
            app.formRequest.category_type_id = "";
            app.category_types    = [];
            app.category_features = [];
        },
        selecteDistrict($event){
            this.selectedDistrictName = $event.target.options[event.target.options.selectedIndex].text;
        },
        selectedLocationHandle($event){
            let app = this;
            app.formRequest.generals.address = $event.address;
            app.formRequest.generals.lat = $event.lat;
            app.formRequest.generals.lon = $event.lon;
        },
        onInputLandSize(event) {
            this.land_size = event.target.value.replace(/-/g, '').match(/(\d{1,10})/g)[0];
			// Format display value based on calculated currencyValue
            this.formRequest.specifications.land_size = this.land_size.replace(/(\d{1,2})(\d{1,2})(\d{1,2})(\d{1,2})/g, '$1-$2-$2-$2');
        },
    }
}
</script>